import * as React from "react";

const SaveIcon = (props) => {
  return (
    <svg viewBox="100 100 500 500" {...props}>
      I love SVG!
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M491.46 345.53c-60.387 0-109.03 49.203-109.03 109.59s48.645 109.03 109.03 109.03c60.387 0 109.59-48.645 109.59-109.03 0-60.387-49.203-109.59-109.59-109.59zm-24.043 133.63 78.836-78.836c13.418-13.418 33.547 6.71 20.129 20.129l-88.902 88.902a14.177 14.177 0 0 1-20.129 0l-40.258-39.699c-13.418-13.418 6.71-33.547 20.129-20.688l30.191 30.191zm-140.9-232.6h161.59c27.957 0 50.879 23.484 50.879 51.441v40.816c-14.539-6.152-30.754-8.945-47.527-8.945-68.773 0-125.25 55.914-125.25 125.25 0 10.625 1.676 21.805 4.473 31.87h-168.86c-27.957 0-50.88-22.925-50.88-51.44v-223.09c0-14.54 11.743-25.16 26.278-25.16h108.47c11.184 0 20.129 7.828 25.719 21.246l15.098 38.02z"
      />
    </svg>
  );
}

export default SaveIcon;
