import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Login } from './components';
import Home from './container/Home';
import Loading from "./assets/Loading.svg";

const App = () => {
    const {user, isAuthenticated, isLoading, error} = useAuth0();

    return error ? <div>Oops... {error.message}</div> : isLoading ? <object type="image/svg+xml" data={Loading} style={{maxHeight:"9rem"}} alt="Animation Loading"></object> : isAuthenticated ? <Home /> : <Login />;
};

export default App;
