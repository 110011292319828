import * as React from "react";

const UploadIcon = (props) => {
  return (
    <svg viewBox="0 0 752 752" {...props}>
      <g fill="#012f49">
        <path d="M316.8 531.39a7.4 7.4 0 0 1-7.402 7.398 7.399 7.399 0 0 1 0-14.796 7.4 7.4 0 0 1 7.402 7.398" />
        <path d="M383.4 420.4h-148c-12.578 0-22.199 9.621-22.199 22.199v96.195c0 12.578 9.621 22.199 22.199 22.199h59.199v22.199h-29.598c-4.441 0-7.398 2.96-7.398 7.398 0 4.442 2.96 7.399 7.398 7.399h88.797c4.441 0 7.398-2.961 7.398-7.399 0-4.441-2.96-7.398-7.398-7.398H324.2v-22.199h59.199c12.578 0 22.199-9.621 22.199-22.199l-.004-96.195c0-12.578-9.621-22.199-22.199-22.199zm7.398 118.39c0 4.441-2.96 7.398-7.398 7.398l-148 .004c-4.441 0-7.398-2.96-7.398-7.398v-22.2h162.79zm0-36.996h-162.79v-59.199c0-4.441 2.961-7.398 7.399-7.398h147.99c4.441 0 7.398 2.96 7.398 7.398zM464.8 324.2H287.21c-31.82 0-59.199-28.859-59.199-62.898.004-34.039 27.383-62.895 59.199-62.895 2.96 0 5.918 0 8.14.738 14.06-27.379 42.18-45.137 73.259-45.137 28.117 0 54.02 14.06 68.816 37 14.059.739 26.64 9.621 32.559 22.2 25.898 2.96 47.359 27.378 47.359 55.495-.739 29.598-25.16 55.496-52.54 55.496zm-177.59-111c-23.68 0-44.398 22.199-44.398 48.098 0 25.898 20.719 48.098 44.398 48.098H464.8c19.238 0 37-19.238 37-40.699 0-21.457-17.762-40.695-37-40.695h-6.66l-1.48-5.918c-2.22-9.621-11.099-16.281-21.458-16.281h-6.66l-2.219-3.7c-11.102-20.718-33.3-33.3-57.719-33.3-27.379 0-51.797 16.28-62.156 41.438l-2.218 5.918-5.918-1.48c-2.965-.735-7.403-1.477-11.105-1.477zM416.7 361.94l-40.699-24.418-40.695 24.418 7.398 13.32 25.898-15.539V405.6h14.801v-45.879l25.898 15.539zM487 597.99h-44.398c-12.578 0-22.199-9.621-22.199-22.199v-125.79c0-12.578 9.621-22.199 22.199-22.199H487c12.578 0 22.199 9.621 22.199 22.199v125.8c0 12.574-9.617 22.195-22.199 22.195zM442.602 442.6c-4.441 0-7.398 2.96-7.398 7.398v125.8c0 4.442 2.96 7.399 7.398 7.399H487c4.441 0 7.398-2.961 7.398-7.399l.004-125.79c0-4.441-2.96-7.398-7.398-7.398z" />
        <path d="M479.6 524h-37v-66.598h37zm-22.199-14.801h7.398v-37h-7.398zM442.6 538.79h44.398v14.801H442.6zm0 22.2h44.398v14.801H442.6z" />
      </g>
    </svg>
  );
}

export default UploadIcon;
