import * as React from "react";

const DeleteIcon = (props) => {
  return (
    <svg viewBox="0 0 752 752" {...props}>
      <path
        fill="#f67f00"
        d="M547.91 205.51c-.473 0-45.938-7.578-104.19-11.84-11.84-12.785-34.57-30.781-68.195-30.781s-56.355 17.996-68.668 30.781c-59.672 4.262-107.03 11.84-107.5 11.84-5.21.945-8.523 5.684-8.05 10.891.945 4.734 4.734 8.05 9.472 8.05h1.422s2.84-.472 7.578-.945c-2.367 56.355-.473 113.19 5.683 168.12 5.211 48.305 14.207 98.031 26.52 147.29 5.211 21.785 23.207 38.36 44.988 42.621 29.363 4.735 59.672 7.579 89.035 7.579 28.887 0 57.305-2.368 84.77-7.579 21.785-4.261 39.31-20.836 44.516-42.148 11.84-48.777 20.836-98.504 26.52-147.76 6.157-56.355 8.051-112.71 5.684-168.12 4.734.946 7.578.946 7.578 1.422 5.211.946 9.945-2.367 10.891-7.578.95-6.156-2.84-10.89-8.05-11.84zM316.8 527.07c-.95.473-1.422.473-2.367.473-4.262 0-8.051-2.84-8.996-7.106-.473-2.367-14.207-60.617-18.941-122.18-4.735-61.566 0-119.82 0-122.18.472-5.21 5.21-8.996 10.418-8.523 5.21.472 8.996 5.21 8.523 10.418 0 .472-4.734 59.199 0 119.34s18.47 118.87 18.941 119.34c.473 4.261-2.844 9.469-7.578 10.418zm66.301-9c0 5.21-4.262 9.473-9.473 9.473-5.21 0-9.472-4.262-9.472-9.473V276.55c0-5.21 4.261-9.473 9.472-9.473 5.211 0 9.473 4.262 9.473 9.473zm-44.516-325.82c9.473-6.156 21.785-10.418 36.938-10.418 14.68 0 26.992 4.262 36.938 10.418-24.625-.95-49.723-.95-73.875 0zm122.66 206c-4.734 61.566-18.469 119.82-18.941 122.18-.945 4.262-4.734 7.106-8.996 7.106-.945 0-1.422 0-2.367-.473-5.211-1.422-8.051-6.156-7.106-11.367 0-.473 14.207-59.2 18.941-119.34s0-118.87 0-119.34c-.472-5.207 3.313-9.942 8.524-10.414 5.21-.473 9.945 3.316 10.418 8.523-.473 3.317 4.261 61.09-.473 123.13z"
      />
    </svg>
  );
}

export default DeleteIcon;
