import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from "../assets/SearchIcon.jsx"

const Navbar = ({ searchTerm, setSearchTerm, user }) => {
  const navigate = useNavigate();

  if (user) {
    return (
      <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7 ">
        <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
          <SearchIcon style={{ maxHeight: "3rem" }} />

          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            value={searchTerm}
            onFocus={() => navigate('/search')}
            className="p-2 w-full bg-white outline-none"
          />
        </div>
        <div className="flex gap-3 ">
          <Link to={`user-profile/${user?._id}`} className="hidden md:block">
            <img src={user.image} alt="user-picutre" className="w-14 h-12 rounded-lg " />
          </Link>
          <Link to="/create-pin" className=" rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="752pt" height="752pt" viewBox="0 0 752 752">
              <g fill="#f67f00">
                <path d="M264.71 411.09c22.434 3.23 46.391 5.207 68.57 1.098v67.188c0 15.996 13.012 29.008 29.008 29.008 15.992 0 29.008-13.012 28.98-28.508 2.508-24.141 2.602-46.52.508-68.273 21.105 1.687 46.023.62 67.727-.508 16.645 0 29.008-13.605 29.008-29.008 0-15.992-13.012-29.004-29.008-29.004l-67.488-.004c3.223-23.805 3.012-46.871-.723-68.645-.11-7.57-3.121-14.68-8.469-20.031-17.762-18.098-49.543-5.637-49.543 20.469v68.207h-68.203c-15.992 0-29.008 13.012-29.008 29.004.004 15.875 12.816 28.812 28.641 29.008zm.367-48.281h73.07a4.868 4.868 0 0 0 4.867-4.867v-73.07c0-17.31 21.121-25.637 32.895-13.621 5.97 5.969 5.403 12.57 5.73 14.449 6.477 37.34-.073 68.867-.073 72.242 0 .207.093.383.117.586.254 2.035 1.644 3.511 3.46 4.02 1.45.41-7.015.183 74.356.257 17.117 0 25.742 20.766 13.586 32.926-3.559 3.629-8.387 5.625-13.836 5.633-63.82 3.293-68.445-.422-73.383.023-1.848.211-3.703 1.684-4.149 3.89-.855 4.286 4.86 25.353-.156 74.095 0 10.629-8.648 19.277-19.273 19.277-10.629 0-19.277-8.649-19.277-19.277-.094-75.598 1.328-76.293-3.5-77.738-1.535-.461-1.422-.223-1.922-.164-.195.023-.383-.028-.578.02-30.793 7.394-68.617-.133-71.934-.133-10.629 0-19.273-8.649-19.273-19.277 0-10.621 8.648-19.27 19.273-19.27zM540.57 184.93l-5.82 27.711a4.871 4.871 0 0 0 3.761 5.766c2.614.543 5.204-1.118 5.758-3.762l5.82-27.711a4.871 4.871 0 0 0-3.761-5.766c-2.613-.539-5.203 1.13-5.758 3.762zm43.54 13.81a4.872 4.872 0 0 0-6.816.938c-5.403 7.113-12.328 13.262-20.027 17.777a4.864 4.864 0 1 0 4.925 8.39c8.79-5.152 16.688-12.164 22.852-20.285a4.877 4.877 0 0 0-.933-6.82zm-22.35 38.62a4.87 4.87 0 0 0-3.727 5.79c.567 2.616 3.165 4.25 5.786 3.726a32.893 32.893 0 0 1 13.887 0c2.777.558 5.238-1.211 5.785-3.727a4.867 4.867 0 0 0-3.727-5.789 43.161 43.161 0 0 0-18.004 0z" />
                <path d="M168.44 549.01c.043 10.918 2.75 14.695 14.945 15.512 114.12 7.644 224.09 8.562 338 2.203 7.106-.398 13.523-4.617 16.602-11.03.855-1.786 2.144-3.7 3.406-5.587 2.453-.203 4.418-2.176 4.473-4.683 2.34-104.93-4.945-196.77-4.945-309.2a4.861 4.861 0 0 0-4.536-4.852c-126.8-8.621-241.18-14.301-356.97 3.625a4.866 4.866 0 0 0-4.117 5c.012.344.02.7.031 1.043-3.117 4.871-6.234 9.742-9.355 14.609.398 97.812 2.07 195.55 2.469 293.36zM531.2 240.78c.184 108.86 7.047 198.18 5.043 299.69-103.3.246-241.62-.273-346.62-.023-3.508-96.582-.914-199.71-4.437-296.48 111.98-16.777 223.18-11.488 346.01-3.188z" />
              </g>
            </svg>

          </Link>
        </div>
      </div>
    );
  }

  return null;
};

export default Navbar;